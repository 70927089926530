<template>
  <v-card class="mb-4" v-if="ventanas.length">
    <v-card-title>
      Datos de Ventanas
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="generarpdf">Generar PDF</v-btn>
      <v-checkbox v-model="hideName" label="Ocultar nombre"></v-checkbox>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="computedHeaders" :items="ventanas" class="elevation-1">
      <template v-slot:item.nombre="{ item }">
        <span v-if="!hideName" class="font-weight-bold">{{ item.nombreFormateado }}</span>
      </template>
      <!-- OPCIONES -->
      <!-- <template v-slot:item.acciones="{ item, index }">
        <v-btn color="primary" class="mr-2" small @click="editWindow(index)">
          <v-icon>mdi-pen</v-icon>
        </v-btn>
        <v-btn color="error" small @click="deleteWindow(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template> -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: "WindowDataTable",
  props: {
    ventanas: {
      type: Array,
      required: true
    },
    idEstudio: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      hideName: false,
      nombreCache: {}
    };
  },
  computed: {
    computedHeaders() {
      let headers = [
        { text: 'Código', value: 'codigo' },
        { text: 'Cantidad', value: 'cantidad' },
        // { text: 'Acciones', value: 'acciones', sortable: false },
      ];
      if (!this.hideName) {
        headers.unshift({ text: 'Nombre', value: 'nombre', align: 'start' });
      }
      return headers;
    }
  },
  methods: {
    async generarpdf() {
  try {
    const { data } = await axios.post(
      `${process.env.VUE_APP_API_URL}/estudios/ventanas/${this.idEstudio}`,
      {}, 
      { responseType: 'blob' }
    );

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', `estudio_ventanas_${this.idEstudio}.pdf`);
    link.click();
    window.URL.revokeObjectURL(url);
    
  } catch (error) {
    console.error("Error al generar el PDF:", error);
  }
},
    async getVentanas(idEstudio) {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/estudio_ventana/detalles/${idEstudio}`);
        const ventanasConNombre = await Promise.all(data.map(async item => {
          const nombre = await this.getName(item.codigo_familia);
          return {
            ...item,
            nombreFormateado: `${nombre} ${item.ancho}x${item.alto}`
          };
        }));
        this.ventanas = ventanasConNombre;
        console.log("Ventanas:", this.ventanas);
      } catch (error) {
        console.error("Error al obtener las ventanas:", error);
      }
    },
    async getName(codigo) {
      if (!codigo) {
        console.error("Código de familia no definido");
        return 'Nombre no encontrado';
      }

      if (this.nombreCache[codigo]) {
        return this.nombreCache[codigo];
      }
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/ventanas/nombre/${codigo}`);
        this.$set(this.nombreCache, codigo, data.nombre); // Cachear el resultado
        return data.nombre;
      } catch (error) {
        return 'Nombre no encontrado';
      }
    },
    async editWindow(index) {
      const window = this.ventanas[index];
      this.$emit('edit-window', window);
    },
    async deleteWindow(index) {
      const window = this.ventanas[index];
      
    }
  },
  created() {
    this.getVentanas(this.idEstudio);
  },
};
</script>
